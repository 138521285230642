.main_layout {
    background-color: #fff;
    justify-content: center;
    align-items: center;

    .content_layout {
        background-color: #fff;
    }
}

.coffer_container {
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.print_button {
    @media print {
        display: none;
    }
}

.coffer_column {
    display: flex;
    flex-direction: column;
    
    &.company_info {
        font-style: italic;
        align-items: flex-end;
    }

    &.coffer_info {
        align-items: flex-start;

        .label {
            font-weight: 700;
        }
    }

    &.coffer_title {
        font-size: 1.5em;
        font-weight: 700;
        align-items: center;
    }
}

.coffer_row {
    display: flex;
    flex-direction: row;

    &.space_between {
        justify-content: space-between;
    }

    .coffer_greeting {
        font-size: 1.1em;
        line-height: 100%;
    }
}

.coffer_logo {
    max-width: 180px;
}

.coffer_stamp {
    max-width: 300px;
}

.coffer_time {
    font-style: italic;
    text-decoration: underline;
}

.coffer_table {
    thead td {
        background-color: #70ad47;
        color: #fff;
    }

    tbody tr:nth-child(odd) {
        background-color: #e2efd9;
    }

    td {
        border: 1px solid #70ad47;
        border-collapse: collapse;        
        text-align: center;
        padding: 0 5px;
        vertical-align: top;

        &.name_td {
            width: 40%;
        }

        &.image_td {
            padding: 5px;
            font-weight: 700;
        }

        &.button_td {
            vertical-align: middle;
        }

        &.sum_td {
            text-align: right;
            font-weight: 700;
        }

        .product_name {
            text-align: left;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 100%;
        }
        .product_desc {
            margin-top: 5px;
            text-align: left;
            font-size: 0.9em;
            line-height: 100%;
            word-break: break-word;
        }

        img {
            width: 70px;
        }
    }

    .highlighted_text {
        font-weight: 700;
    }
}

button.primary {
    background-color: #e2efd9;
    border: 1px solid #70ad47;
    color: #70ad47;
}

button.primary:hover {
    background-color: #70ad47;
    border: 1px solid #70ad47;
    color: #fff;
}

button.secondary {
    background-color: #fff;
    border: 1px solid #70ad47;
    color: #70ad47;
}

button.secondary:hover {
    background-color: #70ad47;
    border: 1px solid #70ad47;
    color: #fff;
}

button.disabled {
    background-color: #fff;
    border: 1px solid grey;
    color: #000;
}

button.disabled:hover {
    background-color: grey;
    border: 1px solid grey;
    color: #fff;
}